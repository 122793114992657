import React, { Component, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PrivateRoute from '../../../Helpers/PrivateRoutes'
import { connect } from 'react-redux'

import { v4 as uuid } from 'uuid';
import { storageGet, storageSet } from '../../../Helpers/Collector/StorageAction'
import { sessionGet, sessionSet } from '../../../Helpers/Collector/SessionAction'
import { getSites } from '../Reducer/AppReducer'





//import Login from '../../Login/Component/Login';
import Layouts from '../../Layout/Component/Layout';
//import Dashboard from '../../Dashboard/Component/Dashboard';

//import Card from '../../Card/Component/Card';
//import Accounts from '../../Accounts/Component/Accounts';

//import Bank from '../../Settings/Bank/Component/Bank';

//import Accounting from '../../Settings/Accounting/Component/Accounting';
//import Address from '../../Settings/Address/Component/Address';
//import Company from '../../Settings/Company/Component/Company';

//import Ebusiness from '../../Ebusiness/Component/Ebusiness';


const Login = React.lazy(() => import('../../Login/Component/Login'));
//const Layouts =  React.lazy(() => import ('../../Layouts/Component/Layouts'));
const Dashboard = React.lazy(() => import('../../Dashboard/Component/Dashboard'));
const Card = React.lazy(() => import('../../Card/Component/Card'));

const Accounts = React.lazy(() => import('../../Accounts/Component/Accounts'));

const Bank = React.lazy(() => import('../../Settings/Bank/Component/Bank'));

const Accounting = React.lazy(() => import('../../Settings/Accounting/Component/Accounting'));

const Address = React.lazy(() => import('../../Settings/Address/Component/Address'));
const Company = React.lazy(() => import('../../Settings/Company/Component/Company'));
const Ebusiness = React.lazy(() => import('../../Ebusiness/Component/Ebusiness'));







export class App extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  init = () => {


    if (Object.keys(storageGet("clientId")).length === 0 && storageGet("clientId").constructor === Object) {
      const datas = {
        key: uuid()
      }
      storageSet("clientId", datas);
    }


    if (Object.keys(sessionGet("sessionId")).length === 0 && sessionGet("sessionId").constructor === Object) {

      const datas = {
        key: uuid()
      }
      sessionSet("sessionId", datas);

    }


    if (Object.keys(storageGet("clientId")).length !== 0 &&
      Object.keys(sessionGet("sessionId")).length !== 0
    ) {


      const datas = {
        clientId: storageGet("clientId"),
        sessionId: sessionGet("sessionId"),
        sId: Object.keys(storageGet("sId")).length !== 0 ? storageGet("sId") : "",
      }
      this.props.getSites(datas);

    }

  }


  componentDidMount() {
    this.init();
  }


  render() {

    const { loading } = this.props.Sites
    return (
      <>
        {!loading &&

          <BrowserRouter>

            <Routes>
              <Route exact element={<PrivateRoute />}>
                <Route exact path="/Dashboard" element={<Layouts><Dashboard /></Layouts>} />
                <Route exact path="/Card" element={
                  <Layouts>
                    <Suspense fallback={<div>Loading</div>}>
                      <Card />
                    </Suspense>
                  </Layouts>} />
                <Route exact path="/Accounts" element={
                  <Layouts>
                    <Suspense fallback={<div>Loading</div>}>
                      <Accounts />
                    </Suspense>
                  </Layouts>} />
                <Route exact path="/Settings/Bank" element={
                  <Layouts>
                    <Suspense fallback={<div>Loading</div>}>
                      <Bank />
                    </Suspense>
                  </Layouts>} />
                <Route exact path="/Settings/Accounting" element={
                  <Layouts>
                    <Suspense fallback={<div>Loading</div>}>
                      <Accounting />
                    </Suspense>
                  </Layouts>} />
                <Route exact path="/Settings/Address" element={
                  <Layouts>
                    <Suspense fallback={<div>Loading</div>}>
                      <Address />
                    </Suspense>
                  </Layouts>} />
                <Route exact path="/Settings/Company" element={<Layouts>
                  <Suspense fallback={<div>Loading</div>}>
                    <Company />
                  </Suspense>
                </Layouts>} />
              </Route>
              <Route path='/Login' element={
                <Suspense fallback={<div>Loading</div>}>
                  <Login />
                </Suspense>} />
              <Route path='/' element={
                <Suspense fallback={<div>Loading</div>}>
                  <Login />
                </Suspense>
              } />
              <Route path='/EB/*' element={
                <Suspense fallback={<div>Loading</div>}>
                  <Ebusiness />
                </Suspense>
              } >
                <Route path=":id" element={
                  <Suspense fallback={<div>Loading</div>}>
                    <Ebusiness />
                  </Suspense>} />
              </Route>
            </Routes>

          </BrowserRouter>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  Sites: state.Sites
})

const mapDispatchToProps = {
  getSites
}

export default connect(mapStateToProps, mapDispatchToProps)(App)


